import React from 'react'
import {
  Container,
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Button from '../Button/Button'
import Link from '../Link/Link'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import CMSText, { isPrismicText, prismicText } from '../Global/CMSText'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 0),
    },
    isWhite: {
      color: theme.palette.common.white,
    },
    isBlack: {
      color: theme.palette.common.black,
    },
    section: {
      position: 'relative',
      overflow: 'hidden',
      padding: theme.spacing(4, 0),
      margin: 'auto',
      '& .MuiContainer-root': {
        position: 'relative',
        zIndex: 2,
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(8, 0),
        display: 'flex',
        minHeight: '90vh',
        maxHeight: 1200,
        flexDirection: 'column',
        justifyContent: 'center',
      },
      '& .content': {
        '& >*:not([data-oembed-type=video])': {
          display: 'block',
          maxWidth: theme.breakpoints.width('xl'),
          padding: theme.spacing(0, 3),
          margin: theme.spacing(3, 'auto'),
        },
      },
    },
    sectionFull: {
      padding: 0,
      '& .MuiContainer-root': {
        padding: 0,
        maxWidth: '100%',
      },
      '& .content': {
        '& >*:not([data-oembed-type=video])': {
          display: 'block',
          maxWidth: theme.breakpoints.width('xl'),
          padding: theme.spacing(0, 3),
          margin: theme.spacing(3, 'auto'),
        },
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: '56.25vh', // Match video ratio
      },
    },
    image: {
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover',
      objectPosition: 'top',
      zIndex: 0,
      height: '100%',
      width: '100%',
    },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      height: '100%',
      width: '100%',
    },
    content: {
      '& *': {
        marginTop: 0,
      },
      '& a': {
        display: 'inline-block',
      },
      '&.left': {
        textAlign: 'left',
      },
      '&.center': {
        textAlign: 'center',
        '& ol, & ul': {
          textAlign: 'left',
        },
      },
      '&.right': {
        textAlign: 'right',
        '& ol, & ul': {
          direction: 'rtl',
        },
      },
    },
    skeletonCta: {
      borderRadius: theme.spacing(3),
      height: 45,
      display: 'inline-block',
    },
    center: {
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
  })
)

type TProps = {
  homePage: any
  t?: any
}

const HerosSection = ({ homePage, t }: TProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { trackEvent } = useMatomo()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div
      className={`section-heros section__spacer ${classes.root}`}
      id="hero-sections"
    >
      {homePage?.data?.hero_sections.length > 0 &&
      homePage?.data?.hero_sections[0].hero_content.length > 0 ? (
        homePage?.data?.hero_sections.map((el, index) => {
          const {
            hero_content,
            hero_background,
            hero_cta_link,
            hero_cta_label,
            align_content,
            hero_title,
            width,
          } = el
          let alignContent: string
          switch (align_content) {
            case 'left':
              alignContent = 'left'
              break
            case 'center':
              alignContent = 'center'
              break
            case 'right':
              alignContent = 'right'
              break
          }
          return (
            <div
              key={index}
              style={{ width: !isMobile ? width : '100%' }}
              className={clsx(classes.section, classes.sectionFull)}
              id={`section-hero--${index + 1}`}
            >
              {hero_background.url !== undefined && (
                <>
                  {useMediaQuery(theme.breakpoints.down('sm')) ? (
                    <img
                      className={clsx('section-hero__image', classes.image)}
                      src={hero_background.small.url}
                      alt={''}
                    />
                  ) : (
                    <img
                      className={clsx('section-hero__image', classes.image)}
                      src={hero_background.url}
                      alt={''}
                    />
                  )}

                  <div
                    className={clsx(
                      'section-hero__overlay',
                      classes.imageOverlay
                    )}
                  ></div>
                </>
              )}
              <Container maxWidth="md">
                <div
                  className={`content ${classes.content} ${alignContent} ${
                    hero_background.url !== undefined
                      ? classes.isWhite
                      : classes.isBlack
                  }`}
                >
                  {isPrismicText(hero_title) && (
                    <Typography variant="h2" className={classes.center}>
                      {prismicText(hero_title)}
                    </Typography>
                  )}
                  {<CMSText data={hero_content} />}
                  {hero_cta_link.url !== undefined &&
                    (hero_cta_label.length !== 0 ? (
                      <Link href={hero_cta_link.url}>
                        <a
                          target={
                            hero_cta_link.target === '_blank'
                              ? '_blank'
                              : '_self'
                          }
                        >
                          <Button
                            variant={'contained'}
                            color={'primary'}
                            component="span"
                            onClick={() =>
                              trackEvent({
                                category: 'HomePage',
                                href: hero_cta_link.url,
                                action: `CTA click ${prismicText(
                                  hero_cta_label
                                )}`,
                                name: `from Hero section to ${hero_cta_link.url}`,
                              })
                            }
                          >
                            {prismicText(hero_cta_label)}
                          </Button>
                        </a>
                      </Link>
                    ) : (
                      <Skeleton
                        variant="rect"
                        className={classes.skeletonCta}
                        width={200}
                        height={45}
                      />
                    ))}
                </div>
              </Container>
            </div>
          )
        })
      ) : (
        <>
          <div className={classes.section}>
            <>
              <img
                className={classes.image}
                src={'/images/placeholder.jpg'}
                alt={''}
              />
              <div className={classes.imageOverlay}></div>
            </>
            <Container maxWidth="md">
              <div
                className={`${classes.content} ${'right'} ${classes.isWhite}`}
              >
                <p>{t('texts:general:add_a_text')}</p>
                <Link href={'/shop'}>
                  <a target={'_blank'}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      component="span"
                    >
                      {t('texts:general:add_a_text')}
                    </Button>
                  </a>
                </Link>
              </div>
            </Container>
          </div>
        </>
      )}
    </div>
  )
}

export default HerosSection
